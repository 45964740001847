
import { computed, defineComponent } from 'vue'

import StepContainer from '@/components/LdStepper/StepContainer/StepContainer.vue'
import StepHeader from '@/components/LdStepper/StepHeader/StepHeader.vue'
import StepFooter from '@/components/LdStepper/StepFooter/StepFooter.vue'
import LdInput from '@/components/LdInput/LdInput.vue'
import * as yup from 'yup'
import { useI18n } from 'vue-i18n'
import { useForm } from 'vee-validate'
import { apiSecret } from '@/lib/validators'
import { getStoreHash } from '@/lib/utilities'
import { useStore } from 'vuex'
import { Steps } from '@/views/connection/Connection.vue'
import { sendPageViewEvent, sendBehaviorEvent, AppVirtualUrls } from '@/services/segmentTracking'
import { gaTypes } from './GaSelection.vue'

enum FORM_FIELDS {
	apiSecret = 'apiSecret',
}

export default defineComponent({
	components: {
		StepContainer,
		StepHeader,
		StepFooter,
		LdInput,
	},

	emits: ['nextPage', ''],
	setup(props, { emit, attrs }) {
		const { t, tm } = useI18n()
		const store = useStore()
		const apiSecretPoints = tm('connection.steps.ga4.points.apiSecret') as any
		const schema = yup.object({
			apiSecret: yup
				.string()
				.matches(apiSecret, t('connection.steps.ga4.apiSecret.error'))
				.required(t('common.required')),
		})
		const { meta, validate, values } = useForm({
			validationSchema: schema,
			validateOnMount: false,
		})
		const userData = computed(() => store.getters['user/me/getState'])
		const storeHash = getStoreHash(userData.value.data.store)
		const onNextPage = async () => {
			const { valid } = await validate()

			sendBehaviorEvent({
				locationTitle: t('connection.steps.ga4.title'),
				virtualUrl: AppVirtualUrls.apiSecret,
				fieldTitle: t('connection.steps.ga4.apiSecret.label'),
				actionId: 'apiSecret',
				actionValue: t('common.continue'),
				actionType: 'button',
			})
			if (valid) {
				store.commit('connection/setApiSecret', values.apiSecret!)
				const { apiSecret, measurementId } = store.state.connection

				if (attrs.selectedRoute === gaTypes.GA4) {
					store.dispatch('connection/properties/updateGAProperties', {
						storeHash,
						measurementId,
						apiSecret,
					})
					emit('nextPage', Steps.LOADING)
				} else {
					emit('nextPage', Steps.PROPERTY)
				}
			}
		}
		return {
			onNextPage,
			FORM_FIELDS,
			meta,
			apiSecretPoints,
			storeHash,
		}
	},
	mounted() {
		const { t } = useI18n()

		sendPageViewEvent({
			locationTitle: t('connection.steps.ga4.title'),
			virtualUrl: 'ga4-api-secret',
		})
	},
})
